// import React, { useState } from 'react'
import Header from '../Header'

const Home = () => {
    return (
      <>
        <Header />
        <div id="body">
          The home body
        </div>
      </>
    );
}

export default Home
