// import React, { useState } from 'react'
import Header from '../Header'
import React, { useRef, useEffect, useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';


import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

mapboxgl.accessToken = 'pk.eyJ1IjoiYm1uIiwiYSI6IjIyODhhYmIxODkyYWQ3ODg2YTE0MjIyN2YzMjA3NzM2In0.OO8AB_ooukoRen9g8E-sjw';

const Car = ({carData}) => {

  console.log('carData is:', carData)

  const carDataRef = useRef(carData);
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(146.9604);
  const [lat, setLat] = useState(-36.7279);
  const [locked, setLocked] = useState(true);
  const [zoom, setZoom] = useState(14);
  const [marker, setMarker] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const timerRef = useRef(null);

  carDataRef.current = carData;
  // const [lockedTimer, setLockedTimer] = useState(-1)
  // let marker;

  const resetLocked = () => {
    console.log('resetLocked fired', carDataRef.current)
    map.current.flyTo({
      center: [carDataRef.current.coords.longitude, carDataRef.current.coords.latitude],
      zoom: zoom,
    });
    setLocked(true)
  }

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [lng, lat],
      zoom: zoom
    });
    map.current.addControl(new mapboxgl.NavigationControl({
      // visualizePitch: true
    }), 'bottom-right');

    const el = document.createElement('div');
    el.className = 'car-marker';

    // make a marker for each feature and add to the map
    setMarker(new mapboxgl.Marker(el).setLngLat([0, 0]).addTo(map.current));

    // setMarker(new mapboxgl.Marker({
    //   color: "#FF0000",
    //   draggable: false
    //   }).setLngLat([0, 0])
    //   .addTo(map.current));
  }, [lng, lat, zoom]);

  useEffect(() => {

    if (!map.current) return; // wait for map to initialize

    if(!initialized) {
      // map.current.on('move', () => {
      //   setLng(map.current.getCenter().lng.toFixed(4));
      //   setLat(map.current.getCenter().lat.toFixed(4));
      //   setZoom(map.current.getZoom().toFixed(2));
      // });
      map.current.on('dragstart', (e) => {
        console.log('dragstart')
        if(timerRef.current) {
          clearTimeout(timerRef.current);
        }
        setLocked(false)
      });
      map.current.on('dragend', (e) => {
        console.log('dragend')
        // setTimeout(() => resetLocked, 10000)
        timerRef.current = setTimeout(resetLocked.bind(this), 2000);
      });
      // map.current.on('zoomend', (e) => {
      //   setZoom(map.current.getZoom())
      // });
      setInitialized(true);
    }
    if(carData) {
      if(marker) {
        marker.setLngLat([carData.coords.longitude, carData.coords.latitude])
        // remove the old heading
        let matches = marker._element.className.match(/rotate-(\d+)/)
        if(matches && matches.length === 2) {
          marker._element.classList.remove(matches[0])
        }
        if(carData.heading != null) {
          marker._element.classList.add('rotate-' + carData.heading)
        }
      }
      if(locked) {
        map.current.flyTo({
          center: [carData.coords.longitude, carData.coords.latitude]
        });
      }
    }
  });
  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <>
      <Header classes="no-shadow" />
      <div id="body" className="no-padding">
      {/* <div className="sidebar">
        Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
      </div> */}
      <div ref={mapContainer} className="map-container" /></div>
    </>
  );
}

export default Car
