
import{Routes, Route}from 'react-router-dom'
import Home from './Components/Pages/Home';
import Car from './Components/Pages/Car';
import Power from './Components/Pages/Power';
// import './App.css';
import { useSnapshot } from "valtio";

import { store } from './store';

function App() {

  const { lastCarPosition } = useSnapshot(store);
  // console.log('last car position is: ', lastCarPosition)

  return (
    <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/power' element={<Power />}></Route>
        <Route path='/car' element={<Car carData={lastCarPosition} />}></Route>
    </Routes>
);
}

export default App;
