import { proxy } from "valtio";
import { io } from "socket.io-client";

interface Device {
  name: string;
  uuid: string;
}

interface Point {
  latitude: number;
  longitude: number;
}

interface CarPosition {
  coords: Point;
  speed: number;
  altitude: number;
  heading: number | null;
}
interface Metadata {
  type: string;
  time: number;
  device: Device;
}
type CarPositionPacket = CarPosition & Metadata

interface ServerResponseDataFormat {
  name: string;
  value: number | string;
  type: string;
}
interface ServerResponseFormat {
  type: string;
  time: number;
  device: Device;
  is_service: boolean;
  live_event: boolean;
  ingress_timestamp: number;
  transport: 'http' | 'mqtt'
  data: ServerResponseDataFormat[]
}

interface MappedData {
  coords: Point;
  speed: number;
  altitude: number;
  heading: number;
}

interface ApplicationState {
  carPositions: CarPositionPacket[] | [];
  lastCarPosition: CarPositionPacket | null
}

export const store = proxy<ApplicationState>({
  carPositions: [],
  lastCarPosition: null
});

// export const getLatestCarPosition = function(): CarPositionPacket {
//   return store.carPositions[store.carPositions.length-1]
// }
export const getLatestCarPosition = (): CarPositionPacket => store.carPositions[store.carPositions.length-1]

// export const getLatestCarPosition:CarPositionPacket => () => (store.carPositions[store.carPositions.length-1]);

// export const onToggle = () => (store.running = !store.running);



const socket = io('/', {
  transports: ["websocket", "polling"] // use WebSocket first, if available
});

socket.on("connect_error", () => {
  // revert to classic upgrade
  socket.io.opts.transports = ["polling", "websocket"];
});

socket.on("connect", () => {
  console.log('we connected!')
});

socket.on("update", (payload: ServerResponseFormat) => {
  if (payload.device.name === 'carpi') {
    // console.log('tis a carpi, do something with it')
    const item:CarPositionPacket = {
      coords: {
        latitude: 123,
        longitude: 123
      },
      speed: 1,
      altitude: 1,
      heading: 1,
      device: {
        name: payload.device.name,
        uuid: payload.device.uuid
      },
      time: payload.time,
      type: payload.type
    }

    // map the data fields into an object
    payload.data.forEach((i, index) => {
      switch(i.name) {
        case 'lat':
          item.coords.latitude = i.value as number
          break;
        case 'lng':
          item.coords.longitude = i.value as number
          break;
        case 'speed':
          item.speed = parseFloat(i.value as string)
          break;
        case 'heading':
          const headingValue = parseFloat(i.value as string)
          item.heading = isNaN(headingValue) ? null : headingValue
          break;
        case 'altitude':
          item.altitude = parseFloat(i.value as string)
          break;
      }
    });

    // store.carPositions.push(item)
    store.lastCarPosition = item;
  }
})