import { useState } from 'react'
import { NavLink } from 'react-router-dom';
import './Header.css';

const data = [
    {
        title: 'Dashboard',
        link: '/',
        emoji: '💻'
    },
    {
        title: 'Power',
        link: '/power',
        emoji: '⚡️'
    },
    {
        title: 'Car',
        link: '/car',
        emoji: '🚗'
    }
];

const Header = ({classes=""}) => {
  const [navs]=useState(data)
  return (
    <div id="header" className={`flex flex-auto-width ${classes}`}>
        {navs.map((nav, index) => (
            <div key={index} className="white-shadow-btn">
                <NavLink
                    to={nav.link}>
                    <span class="menu-emoji">{nav.emoji}</span><span class="menu-text">{nav.title}</span>
                </NavLink>
            </div>
        ))}
    </div>
    );
}

export default Header