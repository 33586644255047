// import React, { useState } from 'react'
import Header from '../Header'

const Power = () => {
    return (
      <>
        <Header />
        <div id="body">
          The power body
        </div>
      </>
    );
}

export default Power
